import { serviceConsoleApi } from '@/utils/request'
import { AxiosRequestConfig, CancelToken } from 'axios'
import { CreateImportDto, UpdateImportDto } from './dto/imports.dto'

export const createImport = (data: CreateImportDto, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/imports`,
        data,
        {
            cancelToken,
        },
    )

export const createImportFile = (data: CreateImportDto, options?: AxiosRequestConfig, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/imports`,
        data,
        {
            ...options,
            cancelToken,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    )

export const updateImport = (importId: number, data: UpdateImportDto, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/imports/${importId}`,
        data,
        {
            cancelToken,
        },
    )

export const startImport = (importId: number, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/imports/${importId}/start`,
        {
            cancelToken,
            timeout: 30000,
        },
    )

export const preprocessImport = (importId: number, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/imports/${importId}/preprocess`,
        {
            cancelToken,
        },
    )

export const uploadFile = (data: any, options?: AxiosRequestConfig, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/imports/upload`,
        data,
        {
            ...options,
            cancelToken,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    )

export const getImports = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/imports`,
        {
            cancelToken,
            params,
        },
    )

export const getImportById = (importId: string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/imports/${importId}`,
        {
            cancelToken,
        },
    )

export const downloadReport = (importId: string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/imports/${importId}/download-report`,
        {
            cancelToken,
            responseType: 'blob',
        },
    )
