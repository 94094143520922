







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ListImportModule } from '@/store/modules/listImport'
import VsContainer from '@/components/VsContainer/Index.vue'

@Component({
    name: 'VsImportFailed',
    components: {
        VsContainer,
    },
})

export default class extends Vue {
    @Prop() contacts!: any
    @Prop() errors!: any
    @Prop() listName!: any
    @Prop() importId!: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get isSuppressionImport () {
        return this.$route.matched.find((el: any) => el.name === 'listImportIndexSuppression')
    }

    get importStep () {
        return ListImportModule.importStep
    }

    set importStep (val) {
        ListImportModule.SET_IMPORT_STEP(val)
    }

    private goToIndexAndClearSteps () {
        this.importStep = []
        this.$router.replace({
            name: this.isSuppressionImport ? 'listImportSelectMethodSuppression' : 'listImportSelectMethod',
            params: {
                listId: this.listId,
            },
        })
    }
}
