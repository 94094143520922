


































import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsExitImportModal',
})
export default class extends Vue {
    private show = false

    public openModal () {
        this.show = true
    }
}
