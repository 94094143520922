enum SourceImportEnum {
    api = 'api',
    manual = 'manual',
    sync = 'sync',
}

export enum SubscriberStatusEnum {
    subscribed = 'Subscribed',
    optIn = 'Opt-In Pending',
}

export enum ImportStrategyEnum {
    file = 'file',
    db = 'db',
    url = 'url',
}

export enum ImportStrategyFileQuoteEnum {
    empty = 'empty',
    double = 'double',
    single = 'single',
}

export enum ImportStrategyFileDelimiterEnum {
    comma = 'comma',
    semicolon = 'semicolon',
    tab = 'tab',
    auto = 'auto',
}

interface TriggersSettingsImport {
    automation?: boolean
    behaviors?: boolean
    send_confirmation_email?: boolean
}

interface SettingsImport {
    update_if_duplicate?: boolean
    subscriber_status?: SubscriberStatusEnum
    triggers?: TriggersSettingsImport
    tags?: number[]
}

interface ImportStrategyFileSettings {
    file: any
    quote: ImportStrategyFileQuoteEnum
    delimiter: ImportStrategyFileDelimiterEnum
    skip_first_row: boolean
}

interface ImportStrategyUrlSettings {
    url: string
    is_paginated: boolean
    timeout: number
    path: string
    page_value: number
    page_key: string
    limit_value: number
    limit_key: string
    response_page_key: string
    response_total_page_key: string
    response_total_page_value: number
}

export interface ImportStrategyDbSettings {
    host: string
    port: number
    database_name: string
    username: string
    password: string
    table_name: string
    condition?: string
    columns?: string[]
    limit?: number
}

export interface CreateImportDto {
    entity_id: string
    entity_type: 'recipient' | 'suppression'
    source?: SourceImportEnum
    settings?: SettingsImport
    import_strategy: ImportStrategyEnum
    import_strategy_settings: ImportStrategyFileSettings | ImportStrategyUrlSettings | ImportStrategyDbSettings
}

export interface UpdateImportDto {
    source?: SourceImportEnum
    settings?: SettingsImport
    import_strategy?: ImportStrategyEnum
    import_strategy_settings?: ImportStrategyFileSettings | ImportStrategyUrlSettings
    mappedFields?: any
}

export interface CreateImportFileDto extends CreateImportDto {
    import_strategy: ImportStrategyEnum.file
    import_strategy_settings: ImportStrategyFileSettings
}

export interface UpdateImportFileDto extends UpdateImportDto {
    import_strategy: ImportStrategyEnum.file
    import_strategy_settings: ImportStrategyFileSettings
}

export interface CreateImportUrlDto extends CreateImportDto {
    import_strategy: ImportStrategyEnum.url
    import_strategy_settings: ImportStrategyUrlSettings
}

export interface UpdateImportUrlDto extends UpdateImportDto {
    import_strategy: ImportStrategyEnum.url
    import_strategy_settings: ImportStrategyUrlSettings
}

export interface CreateImportDbDto extends CreateImportDto {
    import_strategy: ImportStrategyEnum.db
    import_strategy_settings: ImportStrategyDbSettings
}
