









































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getList, getListCustomFields } from '@/api/consoleApi/recipients'
import axios from 'axios'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import VsExitImportModal from '@/modules/lists/components/VsExitImportModal/Index.vue'
import { UserModule } from '@/store/modules/user'
import { ListImportModule } from '@/store/modules/listImport'
import VsCustomFieldsModal from '@/modules/lists/components/VsCustomFieldsModal/Index.vue'
import {
    CustomField,
} from '@/utils/customFields'
import VsImportCompleted from '@/modules/lists/components/VsImportCompleted/Index.vue'
import VsMapField from '@/modules/lists/components/VsMapField/Index.vue'
import VsImportInprogress from '@/modules/lists/components/VsImportInprogress/Index.vue'
import VsImportReview from '@/modules/lists/components/VsImportReview/Index.vue'
import VsImportFailed from '@/modules/lists/components/VsImportFailed/Index.vue'
import { get } from 'lodash'

@Component({
    name: 'ImportIndex',
    components: {
        VsFullTopBarLayout,
        MyAccordion,
        VsExitImportModal,
        VsMapField,
        VsImportInprogress,
        VsImportCompleted,
        VsCustomFieldsModal,
        VsImportFailed,
        VsImportReview,
    },
})
export default class extends Vue {
    private list: any = null
    private customFields: CustomField[] = []
    private loading = false
    private step = 'selectFile'
    private totalContacts = null
    private updated = null
    private errors = null
    private preprocessData = {}
    private mappedFields: any[] = []
    private importId = null

    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get user () {
        return UserModule.user
    }

    get importStep () {
        return ListImportModule.importStep
    }

    set importStep (val) {
        ListImportModule.SET_IMPORT_STEP(val)
    }

    get isSuppressionImport () {
        return this.$route.matched.find((el: any) => el.name === 'listImportIndexSuppression')
    }

    get isSynchronization () {
        return !!this.$route.matched.find((el: any) => el.name === 'createSyncIndex')
    }

    get listName () {
        return this.list?.name || ''
    }

    @Watch('$route', { deep: true })
    private async resetSteps (route: any) {
        if (route.name === 'listImportSelectMethod') {
            this.importStep = []
            this.step = 'selectFile'
            await this.getList(parseInt(this.listId))
            this.getCustomFields()
        }
    }

    async beforeMount () {
        this.loading = true
        this.importStep = []
        await this.getList(parseInt(this.listId))
        this.getCustomFields()
        this.loading = false
    }

    private async getCustomFields () {
        if (this.isSuppressionImport) return
        const resp = await getListCustomFields(this.listId, {
            limit: 100,
        })
        this.customFields = resp.data.data
    }

    private async getList (listId: number) {
        if (this.isSuppressionImport) {
            this.list = {
                name: 'Soppressione',
                id: '',
            }
            return
        }
        try {
            const resp = await getList(listId)
            this.list = resp.data.data
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) this.$router.replace({ name: 'listsIndex' })
            }
            console.log(e)
        }
    }

    private submitSelectDataStep (e: any) {
        this.importStep.push(2)
        this.step = 'mapFields'
        this.preprocessData = e
    }

    private exitFromImport () {
        if (this.importStep.includes(4)) {
            this.close()
            return
        }
        this.$refs.exitImportModal.openModal()
    }

    private close () {
        let routeName = 'importHistory'
        if (this.isSuppressionImport) routeName = 'suppressionList'
        if (this.isSynchronization) routeName = 'listSync'
        this.$router.push({ name: routeName })
    }

    private mapCompleted (mappedFields: any) {
        this.step = 'review'
        this.importStep.push(3)
        this.mappedFields = mappedFields
    }

    private importCompleted (event: any) {
        this.totalContacts = get(event, 'totalContacts', null)
        this.updated = get(event, 'updated', null)
        this.errors = get(event, 'errors', null)
        this.importId = get(event, 'importId', null)
        this.step = event.step
        this.importStep.push(4)
    }
}
