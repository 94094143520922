





























import { Component, Prop, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'

@Component({
    name: 'VsImportInprogress',
    components: {
        VsContainer,
    },
})

export default class extends Vue {
    @Prop() contacts!: any
    @Prop() errors!: any
    @Prop() listName!: any
    @Prop() importId!: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get isSuppressionImport () {
        return this.$route.matched.find((el: any) => el.name === 'listImportIndexSuppression')
    }
}
