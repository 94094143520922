var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsContainer',{attrs:{"fullHeight":"","variant":"narrow"}},[_c('div',{staticClass:"vs-flex vs-flex-col md:vs-flex-row vs-items-center vs-gap-x-10 vs-h-full"},[(!_vm.isSuppressionImport)?_c('div',[_c('div',{staticClass:"vs-mb-10 vs-flex vs-flex-col"},[_c('span',{staticClass:"vs-h600 vs-mb-2"},[_vm._v(_vm._s(_vm.$t('lists.importContacts.importSuccess.title')))]),_c('i18n',{staticClass:"vs-body-large",attrs:{"tag":"div","path":"lists.importContacts.importSuccess.subtitle"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$tc('lists.importContacts.importSuccess.newContacts', _vm.contacts))+" ")]),_c('i18n',{directives:[{name:"show",rawName:"v-show",value:(_vm.updated),expression:"updated"}],attrs:{"tag":"span","path":"lists.importContacts.importSuccess.subtitle2"}},[_c('strong',[_vm._v(_vm._s(_vm.$tc('lists.importContacts.importSuccess.contacts', _vm.updated)))])]),_c('strong',[_vm._v(_vm._s(_vm.listName))])],1),_c('i18n',{staticClass:"vs-body-large",attrs:{"tag":"span","path":"lists.importContacts.importSuccess.errorSentence"}},[_c('span',[_vm._v(_vm._s(_vm.$tc('lists.importContacts.importSuccess.errors', _vm.errors)))]),_c('strong',[_vm._v(_vm._s(_vm.$tc('lists.importContacts.importSuccess.errors2', _vm.errors)))])])],1),_c('div',{staticClass:"vs-flex vs-gap-x-3 md:vs-gap-x-10 vs-items-center"},[_c('VsButton',{attrs:{"size":"large","to":{
                        name: 'campaignsIndex'
                    }}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.importSuccess.createCampaign'))+" ")]),_c('VsButton',{staticClass:"vs-flex-none",attrs:{"variant":"link","to":{
                        name: 'importDetails',
                        params: {
                            listId: _vm.listId,
                            importId: _vm.importId,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.importSuccess.resume'))+" ")])],1)]):_c('div',[_c('div',{staticClass:"vs-mb-10 vs-flex vs-flex-col"},[_c('span',{staticClass:"vs-h600 vs-mb-2"},[_vm._v(_vm._s(_vm.$t('lists.importContacts.importSuccess.title')))]),_c('i18n',{staticClass:"vs-body-large",attrs:{"tag":"span","path":"lists.importContacts.importSuccess.subtitleSuppr"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$tc('lists.importContacts.importSuccess.newContacts', _vm.contacts))+" ")])])],1),_c('div',{staticClass:"vs-flex vs-gap-x-3 md:vs-gap-x-10 vs-items-center"},[_c('VsButton',{attrs:{"size":"large","to":{
                        name: 'suppressionListIndex'
                    }}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.importSuccess.goToSuppressionList'))+" ")]),_c('VsButton',{attrs:{"variant":"link","to":{
                        name: 'suppressionImportDetails',
                        params: {
                            listId: undefined,
                            importId: _vm.importId,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.importSuccess.resume'))+" ")])],1)]),_c('div',{staticClass:"vs-mt-16 md:vs-mt-0"},[_c('img',{attrs:{"src":require("@/assets/img/import/import-completed.png"),"alt":"","width":"100%"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }