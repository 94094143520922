var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsFullTopBarLayout',{scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"vs-flex vs-items-center"},[_c('VsButton',{staticClass:"vs-hidden md:vs-inline-flex",attrs:{"aspect":"secondary"},on:{"click":_vm.exitFromImport}},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.exit'))+" ")]),_c('VsButton',{staticClass:"vs-inline-flex md:vs-hidden",attrs:{"aspect":"secondary","variant":"icon","iconName":"close","size":"small"},on:{"click":_vm.exitFromImport}}),_c('div',{staticClass:"vs-ml-4 vs-flex vs-flex-col"},[(_vm.list)?_c('span',{staticClass:"vs-h400"},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.importContacts'))+" "+_vm._s(_vm.list.name)+" ")]):_vm._e(),_c('div',{staticClass:"vs-h200 vs-flex vs-items-center"},[_c('span',{staticClass:"vs-flex vs-items-center",class:{
                            'vs-hidden md:vs-flex': _vm.step !== 'selectFile',
                            'vs-flex': _vm.step == 'selectFile',
                        }},[(_vm.importStep.includes(2))?_c('VsIcon',{staticClass:"vs-mr-1",attrs:{"name":"success","size":"4","color":"success-500"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.firstStep'))+" ")],1),_c('div',{staticClass:"md:vs-flex vs-items-center"},[_c('VsIcon',{staticClass:"vs-hidden md:vs-flex vs-mx-2",attrs:{"name":"chevron-right","size":"3","color":_vm.importStep.includes(2) ? '' : 'grey-400'}}),_c('span',{class:{
                                'vs-hidden md:vs-flex': _vm.step !== 'mapFields',
                                'vs-flex': _vm.step === 'mapFields',
                            }},[(_vm.importStep.includes(3))?_c('VsIcon',{staticClass:"vs-mr-1",attrs:{"name":"success","size":"4","color":"success-500"}}):_vm._e(),_c('span',{style:({ color: _vm.importStep.includes(2) ? '' : 'var(--vs-color-grey-400)' })},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.secondStep'))+" ")])],1),_c('VsIcon',{staticClass:"vs-hidden md:vs-flex vs-mx-2",attrs:{"name":"chevron-right","size":"3","color":_vm.importStep.includes(3) ? '' : 'grey-400'}}),_c('span',{class:{
                                'vs-hidden md:vs-flex': _vm.step !== 'review',
                                'vs-flex': _vm.step === 'review',
                            }},[(_vm.importStep.includes(4))?_c('VsIcon',{staticClass:"vs-mr-1",attrs:{"name":"success","size":"4","color":"success-500"}}):_vm._e(),_c('span',{style:({ color: _vm.importStep.includes(3) ? '' : 'var(--vs-color-grey-400)'})},[_vm._v(" "+_vm._s(_vm.$t('lists.importContacts.thirdStep'))+" ")])],1)],1)])])],1)]},proxy:true}])},[(_vm.list && _vm.step === 'selectFile')?_c('router-view',{attrs:{"list":_vm.list,"customFields":_vm.customFields},on:{"submit":_vm.submitSelectDataStep}}):_vm._e(),(_vm.step === 'mapFields')?_c('VsMapField',{attrs:{"customFields":_vm.customFields,"list":_vm.list,"preprocessData":_vm.preprocessData},on:{"custom-field-generated":_vm.getCustomFields,"submit":_vm.mapCompleted}}):_vm._e(),(_vm.step === 'review')?_c('VsImportReview',{attrs:{"customFields":_vm.customFields,"list":_vm.list,"mappedFields":_vm.mappedFields,"preprocessData":_vm.preprocessData},on:{"submit":_vm.importCompleted}}):_vm._e(),(_vm.step === 'completed')?_c('VsImportCompleted',{attrs:{"contacts":_vm.totalContacts,"importId":_vm.importId,"updated":_vm.updated,"errors":_vm.errors,"listName":_vm.listName}}):_vm._e(),(_vm.step === 'inprogress')?_c('VsImportInprogress',{attrs:{"importId":_vm.importId}}):_vm._e(),(_vm.step === 'failed')?_c('VsImportFailed',{attrs:{"importId":_vm.importId}}):_vm._e(),_c('VsExitImportModal',{ref:"exitImportModal",on:{"exit":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }