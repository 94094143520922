import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface IListImportState {
    importStep: number[]
}

@Module({ dynamic: true, store, name: 'lists', namespaced: true })
class ListImport extends VuexModule implements IListImportState {
    public importStep: number[] = []

    @Mutation
    public SET_IMPORT_STEP (importStep: number[]) {
        this.importStep = importStep
    }
}

export const ListImportModule = getModule(ListImport)
