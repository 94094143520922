








































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { startImport, updateImport } from '@/api/consoleApi/imports'
import { get, unionBy } from 'lodash'
import { SubscriberStatusEnum, UpdateImportDto } from '@/api/consoleApi/dto/imports.dto'
import { createTag, getTags, updateSynchronization } from '@/api/consoleApi/recipients'
import VsTagNameModal from '@/modules/lists/components/VsTagNameModal/Index.vue'
import axios from 'axios'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsImportReview',
    components: {
        VsContainer,
        VsTagNameModal,
    },
})
export default class extends Vue {
    @Prop({ default: () => [] }) customFields!: any[]
    @Prop({ default: null }) list!: any
    @Prop({ default: null }) preprocessData!: any
    @Prop({ default: () => [] }) mappedFields!: any[]

    private loading = false
    private loadingTags = false
    private skipFirstRow = true
    private status: SubscriberStatusEnum = SubscriberStatusEnum.subscribed
    private optInEmail = false
    private updateIfDuplicate = false
    private triggers = false
    private loadingSubmit = false
    private optinAcceptance = []
    private period = 'monthly'
    private selectedTagMulti: any[] = []
    private tags: any[] = []
    private cachedTags: any[] = []
    private assignedTagIds: any[] = []
    $refs: any

    get isSynchronization () {
        return !!this.$route.matched.find((el: any) => el.name === 'createSyncIndex')
    }

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get user () {
        return UserModule.user
    }

    get hasTag () {
        return this.user.configuration.rules.tag
    }

    get isSuppressionImport () {
        return this.$route.matched.find((el: any) => el.name === 'listImportIndexSuppression')
    }

    get totalRecords () {
        return get(this.preprocessData, 'pre_processing_info.total_records', 0)
    }

    get rows () {
        return get(this.preprocessData, 'pre_processing_info.header', [])
    }

    get tagsOptions () {
        return this.tags.filter(el => !this.assignedTagIds.includes(el.id)).map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })
    }

    async beforeMount () {
        await this.getTags('')
        this.cachedTags = this.tagsOptions
        if (this.list.opt_in && this.list?.opt_in.mode === 'double') this.status = SubscriberStatusEnum.optIn
    }

    private async submitForm () {
        if (this.loadingSubmit) return
        const isValidSelectedTag = this.selectedTagMulti.length <= 3
        if (!isValidSelectedTag && !this.isSuppressionImport) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'inserimento dei contatti, puoi selezionare al massimo tre etichette',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            return
        }
        this.loadingSubmit = true
        try {
            await updateImport(this.preprocessData.id, this.generateSubscribeData())
            const resp = await startImport(this.preprocessData.id)
            const status = get(resp.data.data, 'status', '')
            const importId = get(resp.data.data, 'id', '')
            switch (status) {
                case 'done':
                    this.$emit('submit', {
                        step: 'completed',
                        importId,
                        totalContacts: get(resp.data.data, 'total_new_contacts', ''),
                        updated: get(resp.data.data, 'total_updated_contacts', ''),
                        errors: get(resp.data.data, 'total_error_contacts', ''),
                    })
                    break
                case 'processing':
                    this.$emit('submit', {
                        step: 'inprogress',
                        importId,
                    })
                    break
                case 'failed':
                    this.$emit('submit', {
                        step: 'failed',
                        importId,
                    })
                    break
                default: break
            }
        } catch (e) {
            const serverMsg = get(e, 'response.data.message', '')
            if (serverMsg === 'CannotStartMultipleImport') {
                this.$root.$vsToast({
                    heading: this.$t(`lists.importContacts.review.${serverMsg}`),
                    aspect: VsToastAspectEnum.alert,
                    timeout: 5000,
                })
                this.loadingSubmit = false
                return
            }
            this.$root.$vsToast({
                heading: this.$t('lists.importContacts.review.importError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 5000,
            })
            this.$emit('submit', { step: 'failed' })
        }
        this.loadingSubmit = false
    }

    private async getTags (search: string) {
        this.loadingTags = true
        try {
            const resp = await getTags(
                this.listId,
                {
                    limit: 50,
                    search: `name:${search}`,
                    searchFields: 'name:like',
                })
            this.tags = resp.data.data
            this.setCachedTags(resp.data.data)
        } catch (e) {
            this.tags = []
        }
        this.loadingTags = false
    }

    private setCachedTags (tags: any[]) {
        this.cachedTags = unionBy(
            this.cachedTags,
            tags
                .map((tag: any) => {
                    return {
                        value: tag.id,
                        label: tag.name,
                    }
                }),
            'value',
        )
    }

    async createTag (e: any) {
        try {
            const data = {
                name: e.name,
            }
            await createTag(this.listId, data)
            this.$refs.tagNameModal.closeModal()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Etichetta creato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('tag-created')
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Qualcosa è andato storto',
                aspect: VsToastAspectEnum.alert,
            })
            this.$refs.tagNameModal.loading = false
            console.log(e)
        }
    }

    private generateSubscribeData (): UpdateImportDto {
        const mappedFields = this.mappedFields.reduce((obj: any, item: any) => {
            const field = (obj[item.customFieldId] = item.field, obj)
            return field
        }, {})
        return {
            settings: {
                update_if_duplicate: this.updateIfDuplicate,
                subscriber_status: this.status,
                tags: this.selectedTagMulti && this.selectedTagMulti.length > 0 ? this.selectedTagMulti : undefined,
                triggers: {
                    automation: this.triggers,
                    behaviors: this.triggers,
                    send_confirmation_email: this.optInEmail && this.status !== SubscriberStatusEnum.subscribed,
                },
            },
            mappedFields,
        }
    }

    private async updateSync () {
        if (this.loadingSubmit) return
        this.loadingSubmit = true
        try {
            await updateSynchronization(
                this.listId,
                this.preprocessData.syncId,
                {
                    period: this.period,
                    payload: this.generateSubscribeData(),
                },
            )
            this.$router.push({
                name: 'listSync',
                params: {
                    listId: this.listId,
                },
            })
        } catch (e) {
            console.log(e)
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    if (e.response.data.message['import_strategy_settings.url']) {
                        this.$refs.importUrlForm.setErrors({
                            apiUrl: this.$t('lists.importContacts.fromUrl.errors["' + e.response.data.message['import_strategy_settings.url'] + '"]'),
                        })
                    }
                }
            }
            this.$root.$vsToast({
                heading: this.$t('lists.importContacts.fromUrl.createImportError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }

    formatBadgeLabel (item: any) {
        return this.cachedTags.find((el: any) => el.value === item)?.label || `Etichetta con id ${item} non trovata`
    }
}
